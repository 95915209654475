
.default-ctn {
    @apply px-4 lg:px-20 w-full;
}



.nav-text {
    position: relative;
    @apply text-default px-2 flex items-center justify-center;
    //background: transparent;
    //transition: all 500ms ease;
    z-index: 1;
    cursor: pointer;
    &::after {
        z-index: 2;
        height: 0px;
        //transition: all 500ms ease;
        opacity: 0;
        position: absolute;
        top: 0px;
        left: 50%;
        transform: translateY(100px);
    }
    &:hover {
        //@include my-gradient;
        &::after {
            content : '';
            border-radius: 5px 5px;
            width : 100%;
            height: 100%;
            // background-color: aqua;
            
            transform: translate(-50%);
            @include my-gradient;
            transition: all .5s ease 1s;
            opacity: 1;
            //animation: anim-link 4s ease-in-out linear;
        
            
        }
    };
    & a {
        z-index: 3;
        position: relative;
        text-align: center;
    }
    /*&::before {
        content : '';
        width : 20px ;
        height: 5px;
        // background-color: aqua;
        position: absolute;
        top: 95%;
        left: 50%;
        transform: translateX(-50%);
        @include my-gradient;
        
    }*/

    @keyframes anim-link {
        from {
            height: 0;
        } 
        to {
            height: 100%;
        }
    }
    @layer utilities {
        &--dark {
            @apply text-primary-dark-color;
            &:hover {
                @apply text-default;
                
            }
        }
    }
}

.inner-container {

}

.section-title {
    @apply text-primary-color-stand text-4xl w-full text-center py-4;
    //padding-left: $padding-section;
    //position: relative;
    &--left {
        @apply text-left;
        /*&::before {
            content : '';
            height: 3px;
            position: absolute;
            @apply w-20 primary-stand-color left-0;
            top: 1rem;
            border-radius: 0 5px 5px 0px;
        }*/
    }
}

.icon-size {
    @apply h-6 w-6;

    &-sm {
        @apply h-4 w-4;
    }
}

.spot-tag {
    @apply rounded-full py-1 px-2 text-xs bg-rose-100 text-primary-color-stand;
    @layer utilities {
        &--dark {
            @apply bg-rosin-dark text-default;
        }
    }
}

.border-1 {
    border-width: 1px;
}