
@layer utilities {

    .primary-color {
        @apply bg-liver;
        
    }

    
    .primary-stand-color {
        @apply bg-rose-300;
    }
    
    .text-default {
        @apply text-black;
    }
    
    .text-primary-color {
        @apply text-liver;
    }
    .text-primary-dark-color {
        @apply text-darkin-text;
    }
    .text-primary-color-stand {
        @apply text-rose-500;
    }
    .text-white-color {
        @apply text-white;
    }
}




@mixin primary-bg-color ($dark:false) {

    @if $dark {
        @apply bg-liver-dark;
        &:hover {
            @apply hover:bg-rosin-dark
        }
    } @else {
        @apply bg-liver;
        &:hover {
            @apply hover:bg-rose-500
        }

    }

}

@mixin primary-stand-color ($dark: false) {
    @if $dark {
        @apply bg-rose-400;
    } @else {
        @apply bg-rose-500;
    }
}

@mixin my-gradient($angle : 180deg) {
    /*background: rgb(255,241,242);
    background: linear-gradient(180deg, rgba(255,241,242,1) 0%, rgba(255,228,230,1) 50%, rgba(254,205,211,1) 95%); */
    //background: rgb(255,255,255);
    background: linear-gradient($angle, rgba(255,255,255,1) 15%, rgba(255,228,230,1) 66%, rgba(254,205,211,1) 93%);
    
}

@mixin bg-gradient {

}