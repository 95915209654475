
@import url('https://fonts.googleapis.com/css2?family=Lexend:wght@100;200;300;400;500;600;700;800;900&display=swap');

$f-family : 'Lexend', sans-serif;

// Colors
// Dark Purple : #241623
// Plainum : #EBEBEB
// Dark Liver : #474954

$primary-color : 'bg-liver';

$primary-color-shade : rgba(255,228,230,1);

// F43F5E
$primary-color-stand : rgb(244, 63, 84);

$padding-section : 4rem;
