
.btn-primary {
    @apply p-2 text-center text-white rounded-md shadow block w-fit;

    @include primary-bg-color;
    @layer utilities {
        &--dark {
            @include primary-bg-color(true);
            &:hover {
                @apply text-default;
            }
        }
    }
}
.btn-cta {
    @apply p-2 text-center text-white rounded-md shadow block w-fit;
    @include primary-stand-color;
    @layer utilities {
        &--dark {
            @include primary-stand-color(true);
            &:hover {
                @apply text-default;
            }
        }
    }
}

.btn-link {
    @apply p-2 text-center underline;
    @apply text-primary-color;
    &:hover {
        @apply text-primary-color-stand;
    }
    &--reset {
        @apply p-0;
    }
    @layer utilities {
        &--dark {
            @apply text-primary-dark-color;
            &:hover {
                @apply text-primary-color-stand;
            }
        }
    
    }
}