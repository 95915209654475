@use "sass:color";

.home-page {
    main {
        //@apply outline outline-black;
        & > section {
            @apply min-h-screen;
            
            & > div {
                //padding-top: $padding-section;
                @apply pt-0 lg:pt-16;
                
            }

            .banner-hero {
                //height: 75vh;
                @apply flex flex-col lg:flex-row items-center justify-between;
                .hero-left {
                    @apply w-full lg:w-2/3 flex flex-col;
                    .hero-name {
                        @apply text-5xl text-primary-color my-4;
                    }
                    .greeting {
                        @apply text-default;
                    }
                    .hero-title {
                        @apply text-3xl;
                        span {
                            @apply text-primary-color-stand;
                        }
                        .blink-cursor {
                            @apply text-default bg-black ml-2;
                            animation: blink 1000ms ease-in-out infinite;
                            //vertical-align: 7px;
                            vertical-align: baseline;
                            display: inline-block;
                            height: 1.5rem;
                            width: 3px;
                            
                        }
                    }

                    & > p {
                        @apply my-2;
                        font-weight: 350;
                    }
                    & > .profile-links {
                        @apply flex flex-row gap-2 my-4;
                        & .link {
                            @include primary-bg-color;
                            @apply p-1 rounded-md text-white-color;
                        }
                    }
                }
                .hero-right {
                    @apply w-full max-w-lg flex justify-center items-center py-5 box-border -ml-10;
                    .hero-image {
                        position: relative;
                        @apply p-0 rounded-full w-60 h-60 primary-stand-color;
                        animation: border-anim 2.5s ease-in-out infinite;
                        //border-radius : 40% 70% 70% 30% / 70% 70% 40% 50%;


                        position: relative;
                        overflow: hidden;
                        
                        /*&::after {
                            position: absolute;
                            content: "";
                            top: 0;
                            z-index: -1;
                            @apply p-5 rounded-full w-64 h-64 primary-stand-color;
                            transform: translate(-.5rem, -.5rem) rotate(90deg);
                            animation: border-anim 2s infinite ease-in-out 1s;
                        } */
                        img {
                            @apply rounded-full;
                            position: absolute;
                            //outline: 1px solid blue;
                            transform: scale(1.2);
                        }
                    } 
                    @keyframes border-anim {
                        0% {
                            //border-radius : 60% 40% 30% 70%/60% 30% 70% 40%;
                            border-radius: 60% 40% 20% 50% / 70% 70% 25% 30%;
                        
                        }

                        /*25% {
                            border-radius : 20% 70% 70% 20% / 20% 45% 45% 20%;
                        } */
                        
                        50% {
                            //border-radius : 45% 75% 85% 55%/65% 75% 45% 75%;
                            //border-radius : 70% 70% 25% 30% / 60% 40% 20% 50%;
                            border-radius : 40% 70% 70% 30% / 70% 70% 40% 50%;
                        }

                        /*75% {
                            border-radius : 75% 20% 20% 75% / 40% 20% 20% 40%;
                        }*/
                        
                        100% {
                            //border-radius : 60% 40% 30% 70%/60% 30% 70% 40%;
                            border-radius: 60% 40% 20% 50% / 70% 70% 25% 30%;


                        }
                    }
                }
                
                padding-bottom: 3rem;
                
            }
            .about-me {
                position: relative;
                
                padding-bottom: $padding-section;
                .about-inner {
                    //position: relative;
                    @apply flex flex-col lg:flex-row items-center justify-between py-2;
                    .about-image {
                        //outline: 1px solid red;
                        position: relative;
                        @apply w-5/6 lg:w-2/6 rounded-md;
                        //height: 400px;
                        
                        img {
                            @apply lg:h-[25rem] rounded-md shadow-lg;
                            object-fit: cover;
                            
                            width: 100%;
                        }
                        &::before, .spring-about {
                            content: "";
                            position: absolute;
                            visibility: hidden;
                            width: 100%;
                            height: 100%;
                            z-index: -1;
                            @apply rounded-md;
                            background: color.scale($primary-color-stand, $lightness: 85%);
                        }
                        &:hover {
                            /*.spring-about {
                                visibility: visible;
                                background-color: black;
                            }*/
                            &::before {
                                visibility: visible;
                                top: 0px;
                                left: 0px;
                                transform: translate(-15px, -15px);
                                transition: transform .3s ease-out;
                            }
                        }
                    }
                    .about-desc {
                        @apply w-full mt-2 lg:mt-0 lg:w-3/5 flex flex-col gap-5 lg:mr-12 ;
                        .name {
                            @apply text-3xl;
                        }
                        .occup-title {
                            @apply text-2xl text-primary-color-stand;
                        }
                        & > .about-text {
                            //margin: 1rem 0;
                            font-weight: 350;
                        }
                    }
                }
            }
            .services {
                position: relative;
                padding-top: 2rem;
                padding-bottom: 2rem;

            }

            .skills {
                position: relative;
                
                padding-bottom: $padding-section;
                
                //min-height: 50vh;
                .skill-wrapper {
                    @apply py-3;
                    
                    
                    .skills-ctn {
                        //@apply grid grid-cols-6 gap-y-4 justify-items-center;
                        //display: grid;
                        //width: 75%;
                        //margin: 0 auto;
                        //grid-template-areas: 
                        //". . . a . . ."
                        //"b . c . d . e"
                        //". f . . . g ."
                        //"h . i . j . k"
                        //". . . l . . ."
                        //;
                        //outline:  1px solid blue;
                        //justify-items: center;
                        //row-gap: 2rem;
                        //overflow: hidden;
                        position: relative;
                        //outline: 2px solid red;
                        //min-width: 100%;
                        min-height: 100%;
                        
                        .skills-track {
                            //outline: 2px solid violet;
                            display: flex;
                            //position: absolute;
                            //left: 0;
                            justify-content: center;
                            align-items: center;
                            flex-wrap: wrap;
                            //height: 80px;
                            gap: 16px;
                            width: 100%;
                            height: 100%;
                            .skill-instance {
                                @apply flex flex-col shadow-sm w-24 rounded-md justify-center items-center p-2 border border-1 border-gray-200;
                                .skill-icon {
                                    @apply  flex justify-center items-center p-1 scale-105;
                                }
                                .skill-progress {
                                    .level {
                                        @apply text-sm text-primary-color
                                    }
                                }
                                transition: all .2s ease;
                                &:hover {
                                    @apply shadow-lg text-primary-color-stand;
                                }
                                &:nth-child(1) {
                                    grid-area: a;
                                }
                                &:nth-child(2) {
                                    grid-area: b;
                                }
                                &:nth-child(3) {
                                    grid-area: c;
                                }
                                &:nth-child(4) {
                                    grid-area: d;
                                }
                                &:nth-child(5) {
                                    grid-area: e;
                                }
                                &:nth-child(6) {
                                    grid-area: f;
                                }
                                &:nth-child(7) {
                                    grid-area: g;
                                }
                                &:nth-child(8) {
                                    grid-area: h;
                                }
                                &:nth-child(9) {
                                    grid-area: i;
                                }
                                &:nth-child(10) {
                                    grid-area: j;
                                }
                                &:nth-child(11) {
                                    grid-area: k;
                                }
                                &:nth-child(12) {
                                    grid-area: l;
                                }
                                
                            }
                        }
                    }
                }
            }

            .cases {
                position: relative;
                
                padding-bottom: $padding-section;

                .case-wrapper {
                    
                    .cases-ctn {
                        @apply grid  grid-cols-1 lg:grid-cols-3 gap-12 py-4;
                        .case-instance {
                            @apply p-3 bg-white shadow-lg rounded-md;
                            .case-img {
                                position: relative;
                                @apply h-52 overflow-hidden rounded-md border border-gray-200; 
                                
                            }
                            .case-desc {
                                @apply mt-2;
                                & > h2 {
                                    @apply font-semibold text-lg w-full text-center;
                                }
                                & > p {
                                    @apply text-primary-color text-base text-center;
                                }
                                .stack {
                                    @apply mt-1 flex items-center justify-center gap-2 ;
                                }
                            }
                            .case-link {
                                @apply flex justify-center items-center gap-2 mt-2;
                                & a {
                                    @apply flex items-center;
                                    svg {
                                        @apply ml-2
                                    }
                                }
                            }
                        }
                    }
                }
            }
            .experiences {
                position: relative;
                
                padding-bottom: $padding-section;
                .exp-wrapper {
                    .exp-ctn {
                        .exp-instance {
                            @apply flex items-center p-4 shadow-lg w-1/2 justify-around bg-white rounded-md;
                            .exp-icon {
                                @apply w-40 flex items-center justify-center;
                                & img {
                                    @apply w-32 h-32 p-2 shadow-xl rounded-full;
                                }
                            }
                            .exp-desc {
                                //@apply;
                                .exp-head {
                                    @apply flex justify-start items-center gap-2;
                                    & h1 {
                                        @apply text-2xl text-primary-color-stand
                                    }
                                    &:not(:first-child) {
                                        @apply my-1;
                                    }
                                }
                                svg {
                                    @apply text-primary-color-stand;
                                }
                                .separator {
                                    @apply w-2 h-2 rounded-full primary-stand-color inline-block;
                                }

                                ul {
                                    @apply text-sm mt-2;
                                    li {
                                        @apply mt-1
                                    }
                                }
                            }
                        }
                    }
                }
            }
            .testimonials {
                position: relative;
                
                padding-bottom: $padding-section;
                .testi-wrapper  {
                    @apply py-3;
                    .testi-ctn {
                        @apply mt-10;
                        .testi-instance {
                            @apply flex flex-col gap-1 justify-start w-2/5 p-5 rounded-xl hover:shadow-lg border border-1 border-gray-200;
                            .testi-profile {
                                @apply flex justify-start items-center gap-1;
                                .testi-img {
                                    @apply w-20 h-20 rounded-full p-2 border-2 border-gray-200;
                                }
                                .testi-name {
                                    @apply text-primary-color-stand;
                                }
                            }
                            .testi-desc {
                                p {
                                    @apply text-primary-color;
                                }
                            }
                        }
                    }
                }
            }
            .contact {
                position: relative;
                padding-bottom: 2rem;
                .contact-wrapper {
                    
                    .contact-ctn {
                        @apply w-full lg:w-fit lg:mx-auto;
                        .contact-form {
                            @apply bg-white rounded-md px-10 py-5 shadow-md;
                            & > h3 {
                                @apply text-2xl w-full text-center;
                            }
                            .form-instance {
                                @apply flex flex-col justify-center gap-1 w-72 my-3;
                                input {
                                    @apply border-2 p-2 text-sm rounded-md;
                                    &:focus {
                                        border-color: $primary-color-stand;
                                    }
                                }

                                textarea {
                                    @apply border-2 p-2 text-sm rounded-md;
                                    &:focus {
                                        @apply outline-none;
                                        border-color: $primary-color-stand;
                                    }
                                }

                                &:last-child {
                                    @apply items-center;
                                }
                            }

                        }
                    }
                }
            }
        }
    }

    @layer utilities {
        &--dark {
            main {
                section {
                    .banner-hero {
                        .hero-left {
                            .greeting {
                                @apply text-slate-300;
                            }
                            .link {
                                @include primary-bg-color(true);
                            }
                        }
                    }
                    .about-me {
                        .about-image {
                            &::before, .spring-about {
                                background: color.scale($primary-color-stand, $lightness: 60%);
                            }
                        }
                        
                    }

                    .skills {
                        .skill-instance {
                            @apply bg-liver-rising border-none;
                        }
                    }

                    .cases {
                        .cases-ctn {
                            .case-instance {
                                @apply bg-liver-rising;
                                .case-img {
                                    @apply border-liver-rising;
                                }
                                .case-desc {
                                    & > p {
                                        @apply text-primary-dark-color;
                                    }
                                }
                            }
                        }
                    }
                    .contact {
                        .contact-ctn {
                            .contact-form {
                                @apply bg-liver-rising;

                                .form-instance {
                                    input, textarea {
                                        background: transparent;
                                        &:focus {
                                            @apply border-rosin-dark;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@keyframes blink {
    0% {
        opacity: 0;
    }
    50% {
        opacity: .5;
    } 
    100% {
        opacity: 1;
    }
}